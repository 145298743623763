import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ticket-control"
    }}>{`Ticket control`}</h1>
    <p>{`Ticket control is done by their own control units. These must be able to read the Travel Card or verify the cryptographically protected check sum in the QR code on the mobile ticket.
In order for a ticket to be interoperable, it must be able to be controlled by all parties. Distribution of keys for reading the Travel Card is done manually as a one-time job by agreement with Entur. Mobile tickets, on the other hand, are protected by a daily key that requires regular distribution.
The backend system has services for distributing the cryptographic keys needed to control a ticket on a given Ticket Medium `}{`[HB V821.22 Appendix D - Document provided on request.]`}{`. These are retrieved by PTOs who distribute this to their control units.
The backend system distributes to the PTO the current key and animation for mobile tickets for a period of 30-90 days ahead.`}</p>
    <p>{`Note that NOD solves the safety only of interoperability. It is up to the PTOs to make agreements between themselves for which products are to be valid, and to handle eg distribution of income. These rules must then be implemented in the PTO control units.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      